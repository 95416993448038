import { computed } from 'vue';
import { i18n } from './i18n';
const { t } = i18n.global;

const menuItems = computed(() => [{
    slug: 'contract-data',
    path: '/contract-data',
    name: 'ContractData',
    label: t('contractshub.pages.menuItems.contractData.linkLabel'),
    children: [
        {
            path: '/contract-data/contract-data-master-agreement',
            name: 'ContractDataMasterAgreement',
            label: t('contractshub.pages.menuItems.contractData.children.contractDataMasterAgreement.linkLabel')
        }
    ]
}]);

export {
  menuItems
}