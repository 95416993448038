const Contracts = () => import ( /* webpackChunkName: "Contracts" ) */ "./pages/Contracts");
const ContractsOverview = () => import ( /* webpackChunkName: "ContractsOverview" */ "./pages/Contracts/ContractsOverview");
const ContractDetails = () => import ( /* webpackChunkName: "ContractDetails" */ "./pages/Contracts/ContractDetails");

//const ContractData = () => import( /* webpackChunkName: "ContractData" */ "./pages/ContractData");
//const ContractDataMasterAgreement = () => import( /* webpackChunkName: "ContractDataMasterAgreement" */ "./pages/ContractData/ContractDataMasterAgreement");

const pages = [
    {
        path: '/',
        redirect: '/contracts'
    },
    {
        path: '/contracts',
        name: 'Contracts',
        component: Contracts,
        children: [
            {
                path: "",
                redirect: "/contracts/overview",
            },
            {
                path: "overview",
                name: "ContractsOverview",
                component: ContractsOverview
            },
            {
                path: "details/:contractId",
                name: "ContractDetails",
                slug: "contract-details",
                component: ContractDetails,
                props: true,
            },
        ]
    },
    /*{
        path: '/contract-data',
        name: 'ContractData',
        component: ContractData,
        children: [
            {
                path: '',
                redirect: '/contract-data/contract-data-master-agreement'
            },
            {
                path: 'contract-data-master-agreement',
                name: 'ContractDataMasterAgreement',
                component: ContractDataMasterAgreement
            }
        ]
    }*/
];

export {
    pages
}